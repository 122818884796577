<template>
  <div class="GlobalFormDemo">
    <GlobalForm ref="orderForm" :init-data="initData" :form-item-list="formItemList" :inline="true"
      :isResetdata="isResetForm" :labelWidth="80" round @handleConfirm="handleConfirm" confirmBtnName="查询">
      <el-form-item label="门店商家" slot="companyId" style="margin:0">
        <el-select v-model="companyId" placeholder="请选择" multiple filterable clearable>
          <el-option v-for="item in companyList" :key="item.id" :label="item.companyName"
            :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="报价师" slot="merchantStaffId" style="margin:0">
        <el-select v-model="merchantStaffId" placeholder="请选择" filterable clearable>
          <el-option v-for="item in shanpsSelectList" :key="item.staffId" :label="item.staffName"
            :value="item.staffId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="报价范围" slot="quoteRegionLow" style="margin:0">
        <el-dropdown trigger="click" ref="dropRef" placement="bottom">
          <div class="baoPrice">
            <img src="../../../assets/images/home/icon_sj_.png" alt="">
            <div class="bao_section">
              <p>{{ shortPrice || "最低价" }}</p>
              <div>至</div>
              <p>{{ tallPrice || "最高价" }}</p>
            </div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <div class="pulldown_content">
              <div class="top_aujian">
                <div :class="rangeIndex === index ? 'ta_center_action' : 'ta_center_con'"
                  @click="selectPricerange(index, item)" v-for="(item, index) in pricerangeData" :key="index">
                  {{ item }}
                </div>
              </div>
              <div class="foot_content">
                <div class="fc_input">
                  <div class="put_price"><el-input size="mini" type="number" @input="lowinputchange" v-model="lowPrice"
                      placeholder="最低价"></el-input>
                  </div>
                  <div>至</div>
                  <div class="put_price"><el-input size="mini" type="number" @input="lowinputchange" v-model="uprePrice"
                      placeholder="最高价"></el-input>
                  </div>
                </div>
                <div class="pc_footer">
                  <el-button size="small" @click="resetCommand" >重置</el-button>
                  <el-button @click="backCommand" size="small" type="primary">确定</el-button>
                </div>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>
      <el-form-item label="差值范围" slot="diffRegionLow" style="margin:0">
        <div class="baoPrice">
          <img src="../../../assets/images/home/icon_sj_.png" alt="">
          <div class="bao_section">
            <p class="put_price"><el-input size="mini" clearable maxlength="5" type="number" v-model="lowDifferencevalue"
                placeholder="最低差值"></el-input></p>
            <div>至</div>
            <p class="put_price"><el-input size="mini" clearable maxlength="5" type="number" v-model="talldifferencevalue"
                placeholder="最高差值"></el-input></p>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="下单时间" slot="entryTime" style="margin:0">
        <el-date-picker v-model="entryTime" type="daterange" start-placeholder="开始日期" range-separator="~"
          end-placeholder="结束日期" clearable :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
    </GlobalForm>
  </div>
</template>
  
<script>
import moment from "moment";
import _api from "@/utils/request";
export default {
  name: "Form",
  props: {
    default: () => {
      return [];
    },
    tabIndex: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      merchantId:"",
      talldifferencevalue: "",
      lowDifferencevalue: "",
      rangeIndex: "",
      pricerangeData: [
        "0-50",
        "50-100",
        "100-500",
        "500-1000",
        "1000-1500",
        "1500-2000",
        "2000-2500",
        "2500-3000",
        "3000-4000",
        "4000-5000",
        "5000-8000",
        "8000以上",
      ],
      tallPrice: "",
      shortPrice: "",
      ulPricedata: [],
      uprePrice: "",
      lowPrice: "",
      isResetForm: false,
      merchantStaffId: "",//报价师
      state: '',//订单状态
      companyId: [],
      companyList: [],
      entryTime: null,
      shanpsSelectList: [],
      initData: {
      },
      startTime: "",
      endTime: "",
      formItemList: [
        // {
        //   key: "orderNo",
        //   type: "input",
        //   labelName: "门店商家",
        //   placeholder: "请输入商家名称查询",
        // },
        { slotName: "companyId" },
        {
          key: "orderNo",
          type: "input",
          labelName: "订单编号",
          placeholder: "请输入订单编号",
        },
        {
          key: "phoneName",
          type: "input",
          labelName: "机型名称",
          placeholder: "请输入机型名称",
        },
        { slotName: "merchantStaffId" },//报价师
        { slotName: "quoteRegionLow" },//报价范围
        { slotName: "diffRegionLow" },//差值范围
        { slotName: "entryTime" },//下单时间
      ],
      SeachParams: {},
    };
  },
  created() {
    this.merchantId=this.$route.query.id
    let startTime = moment()
      .day(moment().day() - 30)
      .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推7天的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    this.entryTime = [startTime, endTime];
    // 报价师
    _api.recycleStaffList({ positionType: '', merchantId: this.merchantId }).then((res) => {
      if (res.code === 1) {
        this.shanpsSelectList = res.data;
      }
    });
    _api.listCompany({ merchantId: this.merchantId, pageSize: 99999 }).then((res) => {
      const list = res.data.ipage.records
      this.companyList = list.filter(item => item.applyStatus != '00')
    });
  },
  methods: {
    //选择报价区间
    selectPricerange(index, data) {
      this.rangeIndex = index
      if(data=='8000以上'){
        this.ulPricedata = ['8000','9999999']
      }else{
        this.ulPricedata = data.split("-")
      }
      this.uprePrice = ""
      this.lowPrice = ""
    },
    //输入报价区间
    lowinputchange(e) {
      console.log(e);
      if (e) {
        this.rangeIndex = ""
        this.ulPricedata = []
      }
    },
    //重置
    resetCommand() {
      this.rangeIndex = ""
      this.ulPricedata = []
      this.tallPrice = ""
      this.shortPrice = ""
      this.uprePrice = ""
      this.lowPrice = ""
      this.handleConfirm({})
      //关闭菜单
      this.$refs.dropRef.hide();
      //开启菜单
      // this.$refs.dropRef.show();
    },
    //确定
    backCommand(e) {
      if (this.uprePrice || this.lowPrice) {
        if (this.uprePrice && this.lowPrice) {
          if (Number(this.lowPrice) >= Number(this.uprePrice)) {
            return this.$message.error('最低价不能大于或等于最高价！');
          } else {
            this.tallPrice = this.uprePrice
            this.shortPrice = this.lowPrice
          }
        } else {
          return this.$message.error('请输入完整的报价区间！');
        }
      } else {
        this.shortPrice = this.ulPricedata[0]
        this.tallPrice = this.ulPricedata[1]
      }
      this.handleConfirm({})
      //关闭菜单
      this.$refs.dropRef.hide();
      //开启菜单
      // this.$refs.dropRef.show();
    },
    //查询****
    handleConfirm(data) {
      console.log("提交了form", data);
      console.log(this.entryTime);
      if (this.lowDifferencevalue && this.talldifferencevalue) {
        if (Number(this.lowDifferencevalue) >= Number(this.talldifferencevalue)) {
          return this.$message.error('最低差值不能大于或等于最高差值！');
        }
      } else if (this.lowDifferencevalue || this.talldifferencevalue) {
        return this.$message.error('请输入完整的差值范围！');
      }
      if (this.entryTime === null) {
        (this.startTime = ""), (this.endTime = "");
      } else {
        (this.startTime = moment(this.entryTime[0]).format("x")),
          (this.endTime = moment(this.entryTime[1]).format("x"));
      }
      const baseRequest = {
        orderNo: data.orderNo,
        phoneName: data.phoneName,
        diffRegionLow: this.lowDifferencevalue,
        diffRegionUp: this.talldifferencevalue,
        quoteRegionLow: this.shortPrice,
        quoteRegionUp: this.tallPrice,
        merchantStaffId: this.merchantStaffId,
        companyId: this.companyId,
        endTime: this.endTime,
        startTime: this.startTime,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.SeachParams = baseRequest;
      console.log(baseRequest, this.SeachParams, "参数333");
      this.$store.commit("tagsView/SEACH_PARAMS", baseRequest);

    },
  },
};
</script>
  
<style scoped lang="scss" ref="stylesheet/scss">
.top_aujian {
  overflow: auto;
  width: 225px;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ta_center_con {
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  margin-bottom: 14px;
  color: #333333;
  width: 100px;
  height: 30px;
  background: #F3F3F3;
  border-radius: 4px;
}

.ta_center_action {
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  margin-bottom: 14px;
  color: #0981FF;
  width: 100px;
  height: 30px;
  background: #E4ECFD;
  border-radius: 4px;
}

/deep/.query .el-tag.el-tag--info {
  background-color: #fbfbfb;
  border-color: #cecdcd;
  color: #666666;
}

.baoPrice {
  border: 1px solid #e4ecfd;
  width: 421px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  padding: 3px 10px;

  >img {
    width: 15px;
    height: 15px;
  }
}

.bao_section {
  flex: 1;
  font-size: 12px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #A1A4AF;
  // width: 300px;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
}

.pulldown_content {
  width: 265px;
  padding: 0 20px;
  height: 300px;
  position: relative;
}

.fc_input {
  font-size: 12px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px auto;
  width: 225px;
  height: 30px;
  // background: #F9FBFD;
  border: 1px solid #C1D1FF;
  border-radius: 4px;
}

.put_price {
  width: 80px;

  /deep/.el-input__inner {
    border: none !important;
  }
}

.foot_content {
  position: absolute;
  bottom: 0;
  width: 225px;
  height: 95px;
  // box-shadow: 0px 2px 14px 1px rgba(118, 133, 164, 0.31);
}

/deep/.el-dropdown-menu__item {
  line-height: 30px !important;
  padding: 0 !important;
}

.pc_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.GlobalFormDemo {
  /deep/.el-checkbox-group {
    margin-left: 15px;
  }

  // /deep/.el-form-item--small.el-form-item{
  //   margin-bottom: 0;
  // }
  .flex {
    display: flex;
    align-items: center;
  }

  .time-type {
    /deep/.el-input__inner {
      border: none;
    }
  }
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type='number'] {
  -moz-appearance: textfield;
}
</style>
  