<template>
  <div class="GlobalTable">
    <GlobalTable ref="GlobalTable" class="sample-image" v-loading="loading" :columns="tableColumns" :isPagination="false"
      :maxHeight="700" :data="seachDataList" @handleCurrentChange="handleCurrentChange">
      <el-table-column slot="orderNumObj" label="询价量" align="center">
        <template slot-scope="{row}">
          <span>{{ row.orderNumObj.chooseData }}</span>
          <div v-if="row.orderNumObj.thanUp">
            <div v-if="row.orderNumObj.thanUp == '00'" class="equationBlue"><img
                src="../../../assets/images/home/951@2x.png" style="width: 8px">{{ row.orderNumObj.thanData }}%</div>
            <div v-else-if="row.orderNumObj.thanUp == '01'" class="equationGreen"><img
                src="../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ row.orderNumObj.thanData }}%
            </div>
            <div v-else-if="row.orderNumObj.thanUp == '02'" class="equationRed"><img
                src="../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ row.orderNumObj.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column slot="quoteNumObj" label="报价量" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>报价量</div>
            <div>
              <el-tooltip class="item" effect="dark" content="所有完成报价的订单，包含拒绝报价单量" placement="top">
                <img src="../../../assets/images/home/ico_ts_red_.png" width="14px" height="14px" alt="" />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="{row}">
          <span>{{ row.quoteNumObj.chooseData }}</span>
          <div v-if="row.quoteNumObj.thanUp">
            <div v-if="row.quoteNumObj.thanUp == '00'" class="equationBlue"><img
                src="../../../assets/images/home/951@2x.png" style="width: 8px">{{ row.quoteNumObj.thanData }}%</div>
            <div v-else-if="row.quoteNumObj.thanUp == '01'" class="equationGreen"><img
                src="../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ row.quoteNumObj.thanData }}%
            </div>
            <div v-else-if="row.quoteNumObj.thanUp == '02'" class="equationRed"><img
                src="../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ row.quoteNumObj.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column slot="rejectQuoteNumObj" label="拒绝报价量" align="center">
        <template slot-scope="{row}">
          <span>{{ row.rejectQuoteNumObj.chooseData }}</span>
          <div v-if="row.rejectQuoteNumObj.thanUp">
            <div v-if="row.rejectQuoteNumObj.thanUp == '00'" class="equationBlue"><img
                src="../../../assets/images/home/951@2x.png" style="width: 8px">{{ row.rejectQuoteNumObj.thanData }}%
            </div>
            <div v-else-if="row.rejectQuoteNumObj.thanUp == '01'" class="equationGreen"><img
                src="../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ row.rejectQuoteNumObj.thanData
                }}%</div>
            <div v-else-if="row.rejectQuoteNumObj.thanUp == '02'" class="equationRed"><img
                src="../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ row.rejectQuoteNumObj.thanData
                }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column slot="ackOrderNumObj" label="总成交订单" align="center">
        <template slot-scope="{row}">
          <span>{{ row.ackOrderNumObj.chooseData }}</span>
          <div v-if="row.ackOrderNumObj.thanUp">
            <div v-if="row.ackOrderNumObj.thanUp == '00'" class="equationBlue"><img
                src="../../../assets/images/home/951@2x.png" style="width: 8px">{{ row.ackOrderNumObj.thanData }}%</div>
            <div v-else-if="row.ackOrderNumObj.thanUp == '01'" class="equationGreen"><img
                src="../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ row.ackOrderNumObj.thanData }}%
            </div>
            <div v-else-if="row.ackOrderNumObj.thanUp == '02'" class="equationRed"><img
                src="../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ row.ackOrderNumObj.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column slot="failOrderNumObj" label="非最高报价订单" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>非最高报价订单</div>
            <div>
              <el-tooltip class="item" effect="dark" content="在所有您报价单量中，您的报价并非最高的订单量，不含拒绝报价订单" placement="top">
                <img src="../../../assets/images/home/ico_ts_red_.png" width="14px" height="14px" alt="" />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="{row}">
          <span @click="seaCollectionorder(row)" style="color: #0981FF;text-decoration: underline; cursor: pointer;">{{
            row.failOrderNumObj.chooseData }}</span>
          <div v-if="row.failOrderNumObj.thanUp">
            <div v-if="row.failOrderNumObj.thanUp == '00'" class="equationBlue"><img
                src="../../../assets/images/home/951@2x.png" style="width: 8px">{{ row.failOrderNumObj.thanData }}%</div>
            <div v-else-if="row.failOrderNumObj.thanUp == '01'" class="equationGreen"><img
                src="../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ row.failOrderNumObj.thanData }}%
            </div>
            <div v-else-if="row.failOrderNumObj.thanUp == '02'" class="equationRed"><img
                src="../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ row.failOrderNumObj.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column slot="selfAvgQuotePriceObj" label="我方平均报价(元)" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>我方平均报价(元)</div>
            <div>
              <el-tooltip class="item" effect="dark" content="非最高报价订单中，我方报价金额/报价量" placement="top">
                <img src="../../../assets/images/home/ico_ts_red_.png" width="14px" height="14px" alt="" />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="{row}">
          <span>{{ row.selfAvgQuotePriceObj.chooseData }}</span>
          <div v-if="row.selfAvgQuotePriceObj.thanUp">
            <div v-if="row.selfAvgQuotePriceObj.thanUp == '00'" class="equationBlue"><img
                src="../../../assets/images/home/951@2x.png" style="width: 8px">{{ row.selfAvgQuotePriceObj.thanData }}%
            </div>
            <div v-else-if="row.selfAvgQuotePriceObj.thanUp == '01'" class="equationGreen"><img
                src="../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{
                  row.selfAvgQuotePriceObj.thanData }}%</div>
            <div v-else-if="row.selfAvgQuotePriceObj.thanUp == '02'" class="equationRed"><img
                src="../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{
                  row.selfAvgQuotePriceObj.thanData }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column slot="platformMaxAvgObj" label="平台平均最高价(元)" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>平台平均最高价(元)</div>
            <div>
              <el-tooltip class="item" effect="dark" content="非最高报价订单中，平台最高报价金额总和/单量" placement="top">
                <img src="../../../assets/images/home/ico_ts_red_.png" width="14px" height="14px" alt="" />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="{row}">
          <span>{{ row.platformMaxAvgObj.chooseData }}</span>
          <div v-if="row.platformMaxAvgObj.thanUp">
            <div v-if="row.platformMaxAvgObj.thanUp == '00'" class="equationBlue"><img
                src="../../../assets/images/home/951@2x.png" style="width: 8px">{{ row.platformMaxAvgObj.thanData }}%
            </div>
            <div v-else-if="row.platformMaxAvgObj.thanUp == '01'" class="equationGreen"><img
                src="../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ row.platformMaxAvgObj.thanData
                }}%</div>
            <div v-else-if="row.platformMaxAvgObj.thanUp == '02'" class="equationRed"><img
                src="../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ row.platformMaxAvgObj.thanData
                }}%</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="平均差值" slot="avgDiffObj" align="center">
        <template slot-scope="{ row }">
          <span style="color: #67c23a;" v-if="row.avgDiffObj.chooseData > 0">{{ row.avgDiffObj.chooseData }}</span>
          <span style="color: #ff687b;" v-else>{{ row.avgDiffObj.chooseData }}</span>
          <div v-if="row.avgDiffObj.thanUp">
            <div v-if="row.avgDiffObj.thanUp == '00'" class="equationBlue"><img
                src="../../../assets/images/home/951@2x.png" style="width: 8px">{{ row.avgDiffObj.thanData }}%</div>
            <div v-else-if="row.avgDiffObj.thanUp == '01'" class="equationGreen"><img
                src="../../../assets/images/home/icon_shqs_@2x.png" style="width: 8px">{{ row.avgDiffObj.thanData }}%
            </div>
            <div v-else-if="row.avgDiffObj.thanUp == '02'" class="equationRed"><img
                src="../../../assets/images/home/icon_xjqs_@2x.png" style="width: 8px">{{ row.avgDiffObj.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="150px"  label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="danger" plain @click="handleBtn(row, 'edit')">不同规格报价分析</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!--采集订单明细弹框-->
    <el-dialog title="采集订单明细" :visible.sync="dialogVisible" :close-on-click-modal="false" width="1000px"
      @closed="handleDialogClosed">
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="订单编号">
            <el-input v-model="formInline.orderNo" placeholder="请输入订单编号" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="serchBindSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <GlobalTable ref="GlobalTable" class="sample-image" v-loading="loading" :columns="collectionColumns"
        :data="collectDataList" :currentPage="collectpage.pageNum" :total="collectpage.total"
        @handleCurrentChange="collectCurrentChange">
        <el-table-column label="订单号" slot="orderNo" align="center">
          <template slot-scope="{ row }">
            <span style="color: #0981FF;text-decoration: underline; cursor: pointer;" @click="handleClick(row)">{{
              row.orderNo }}</span>
          </template>
        </el-table-column>
        <el-table-column slot="healthCodeAnalyse" label="分析成色等级" align="center">
          <template slot-scope="{ row }">
            <span>
              {{
                row.healthCodeAnalyse == 'Nice' ? '靓机' : row.healthCodeAnalyse == 'Little_Snow' ? '小花' :
                row.healthCodeAnalyse == 'Big_Snow' ? '大花' : row.healthCodeAnalyse == 'Out_Burst' ? '外爆' :
                  row.healthCodeAnalyse == 'Inner_Burst' ? '内爆' : row.healthCodeAnalyse == 'Repair' ? '疑似维修' :
                    row.healthCodeAnalyse == 'NoImage' ? '照片缺失' : row.healthCodeAnalyse == 'PhoneNoMatching' ? '机型与所选不一致' :
                      '未划分等级' }}
            </span>
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>
    <!-- 非最高报价分析弹框 -->
    <el-dialog title='非最高报价分析' :visible.sync="markShow" :close-on-click-modal="false" width="1000px"
      @closed=" markShow = false">
      <div class="enable-tip" style="margin-bottom: 20px;">
        <span
          v-if="SeachParams.dateType">查询时间：{{ SeachParams.dateType == '01' ? '今日' : SeachParams.dateType == '03' ? '本周' : '本月' }}</span>
        <span v-else>查询时间：{{ timestampToTime(SeachParams.startTime) }}~{{ timestampToTime(SeachParams.endTime) }}</span>
      </div>
      <div style="margin-bottom: 20px;">
        <el-table ref="addtipleTable" :default-sort="{ prop: 'orderNum', order: 'descending' }" :data="opymodelList"
          @sort-change="rankChange" tooltip-effect="dark" border max-height="530" style="width: 100%">
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column label="机型" align="center">
            <template slot-scope="{ row }">{{ row.phoneName || "--" }}</template>
          </el-table-column>
          <el-table-column label="规格" align="center">
            <template slot-scope="{ row }">{{ row.skuName || "--" }}</template>
          </el-table-column>
          <el-table-column label="询价量" prop="orderNum" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">{{ row.orderNum }}</template>
          </el-table-column>
          <el-table-column label="报价量" prop="quoteNum" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">{{ row.quoteNum }}</template>
          </el-table-column>
          <el-table-column label="拒绝报价量" prop="rejectQuoteNum" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">{{ row.rejectQuoteNum }}</template>
          </el-table-column>
          <el-table-column label="成交量" prop="ackOrderNum" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">{{ row.ackOrderNum }}</template>
          </el-table-column>
          <el-table-column label="非最高报价订单" prop="failOrderNum" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">
              <span @click="notongCollectionorder(row)"
                style="color: #0981FF;text-decoration: underline; cursor: pointer;">{{ row.failOrderNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="我方平均报价" prop="selfAvgQuotePrice" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">{{ row.selfAvgQuotePrice }}</template>
          </el-table-column>
          <el-table-column label="平台平均最高价" prop="platformMaxAvg" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">{{ row.platformMaxAvg }}</template>
          </el-table-column>
          <el-table-column label="平均差值" prop="avgDiff" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">
              <span style="color: #67c23a;" v-if="row.avgDiff > 0">{{ row.avgDiff }}</span>
              <span style="color: #ff687b;" v-else>{{ row.avgDiff }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="150px" fixed="right">
            <template slot-scope="{ row }">
              <el-button size="mini" type="primary" plain @click="gradeBtn(row)">不同等级分析</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 不同等级报价分析  -->
    <el-dialog :title="'不同等级报价分析-' + failOrderdata.phoneName + '(' + (failOrderdata.skuName || '无') + ')'"
      :visible.sync="bindDloding" :close-on-click-modal="false" width="1000px" @closed="bindDloding = false">
      <div class="enable-tip" style="margin-bottom: 20px;">
        <span
          v-if="SeachParams.dateType">查询时间：{{ SeachParams.dateType == '01' ? '今日' : SeachParams.dateType == '03' ? '本周' : '本月' }}</span>
        <span v-else>查询时间：{{ timestampToTime(SeachParams.startTime) }}~{{ timestampToTime(SeachParams.endTime) }}</span>
        <span style="margin-left: 50px;">非最高报价订单：{{ failOrderdata.failOrderNum }}单</span>
      </div>
      <div style="margin-bottom: 20px;">
        <el-table ref="addtipleTable" :default-sort="{ prop: 'orderNum', order: 'descending' }" :data="grademodelList"
          tooltip-effect="dark" border max-height="530" style="width: 100%">
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column label="标签" align="center" prop="healthCodeCus">
            <template slot-scope="{ row }"><span>{{
              row.healthCodeCus == 'Nice' ? '靓机' : row.healthCodeCus == 'Little_Snow' ? '小花' :
              row.healthCodeCus == 'Big_Snow' ? '大花' : row.healthCodeCus == 'Out_Burst' ? '外爆' :
                row.healthCodeCus == 'Inner_Burst' ? '内爆' : row.healthCodeCus == 'Repair' ? '疑似维修' :
                  row.healthCodeCus == 'NoImage' ? '照片缺失' : row.healthCodeCus == 'PhoneNoMatching' ? '机型与所选不一致' :
                    '未划分等级' }}</span></template>
          </el-table-column>
          <el-table-column label="询价量" prop="orderNum" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">{{ row.orderNum || "--" }}</template>
          </el-table-column>
          <el-table-column label="有效报价量" prop="validQuoteNum" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">{{ row.validQuoteNum }}</template>
          </el-table-column>
          <el-table-column label="未报价量" prop="unQuoteNum" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">{{ row.unQuoteNum }}</template>
          </el-table-column>
          <el-table-column label="拒绝报价量" prop="rejectQuoteNum" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">{{ row.rejectQuoteNum }}</template>
          </el-table-column>
          <el-table-column label="成交量" prop="ackOrderNum" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">{{ row.ackOrderNum }}</template>
          </el-table-column>
          <el-table-column label="非最高报价订单" prop="failOrderNum" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">
              <span @click="differentbrackets(row)" style="color: #0981FF;text-decoration: underline; cursor: pointer;">{{
                row.failOrderNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="我方平均报价" prop="selfAvgQuotePrice" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">{{ row.selfAvgQuotePrice }}</template>
          </el-table-column>
          <el-table-column label="平台平均最高价" prop="platformMaxAvg" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }">{{ row.platformMaxAvg }}</template>
          </el-table-column>
          <el-table-column label="平均差值" prop="avgDiff" sortable :sort-orders="['descending', 'ascending']" align="center">
            <template slot-scope="{ row }"> 
              <span style="color: #67c23a;" v-if="row.avgDiff > 0">{{ row.avgDiff }}</span>
              <span style="color: #ff687b;" v-else>{{ row.avgDiff }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Table",
  props: {
    classList: Array,
    oldBrand: Array,
  },
  data() {
    return {
      healthCodeAnalyse:"",
      sku:"",
      merchantId:"",
      copyphoneName: "",
      copypbrandName: "",
      failOrderdata: {},
      bindDloding: false,
      formInline: {
        orderNo: ""
      },
      loading: false,
      opymodelList: [],
      grademodelList: [],
      markShow: false,// 非最高报价分析弹框
      seachDataList: [],
      collectDataList: [],
      modelform: {},
      dialogVisible: false, // 采集订单明细弹框
      dialogTitle: "", // 标题
      tableColumns: [
        { label: "分类", prop: "typeName" },
        { label: "品牌", prop: "brandName" },
        { label: "机型名称", prop: "phoneName" },
        { slotName: "orderNumObj" },//询价量
        { slotName: "quoteNumObj" },//报价量
        { slotName: "rejectQuoteNumObj" },//拒绝报价量
        { slotName: "ackOrderNumObj" },//总成交订单
        { slotName: "failOrderNumObj" },//非最高报价订单
        { slotName: "selfAvgQuotePriceObj" },//我方平均报价
        { slotName: "platformMaxAvgObj" },//平台平均最高价
        { slotName: "avgDiffObj" },//平均差值
        { slotName: "operation" }
      ],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      collectionColumns: [
        { slotName: "orderNo" },
        { label: "机型", prop: "phoneName" },
        { label: "规格", prop: "skuName" },
        { slotName: "healthCodeAnalyse" },
        { label: "我方报价", prop: "quotePrice" },
        { label: "平台最高报价", prop: "maxQuotePrice" },
        { label: "与最高价差值", prop: "diff" },
      ],
      timedataCope: {

      },
      collectpage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      }
    };
  },
  created() {
    this.merchantId=this.$route.query.id
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    }
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.pageNum = 1
      this.handleCurrentChange();
    }
  },
  methods: {
    timestampToTime(timestamp) {
      // 时间戳为10位需*1000，时间戳为13位不需乘1000
      // var date = new Date(timestamp * 1000);
      var date = new Date(Number(timestamp));
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10 ?
          "0" + (date.getMonth() + 1) :
          date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = date.getSeconds();
      return Y + M + D
      // + h + m + s;
    },
    handleClick(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: row.orderNo },
      });
      window.open(routeData.href, "_blank");
    },
   //采集订单明细
   seaCollectionorder(row) {
      console.log(row);
      this.copyphoneName = row.phoneName
      this.healthCodeAnalyse=""
      this.sku=""
      this.collectCurrentChange(1)
      this.dialogVisible = true
    },
   //采集订单明细
   notongCollectionorder(row){
      console.log(row);
      this.copyphoneName = row.phoneName
      this.sku=row.skuName
      this.healthCodeAnalyse=""
      this.collectCurrentChange(1)
      this.dialogVisible = true
    },
    //采集订单明细
    differentbrackets(row) {
      console.log(row);
      this.sku=this.failOrderdata.skuName
      this.healthCodeAnalyse=row.healthCodeCus
      this.collectCurrentChange(1)
      this.dialogVisible = true
    },
    //非最高报价分析排序
    rankChange(e) {

    },
    //不同等级报价分析排序
    gradeChange(e) {
      console.log(e);
    },
    //批量绑码搜索商家
    serchBindSubmit() {
      this.collectCurrentChange(1)
    },
    collectCurrentChange(val) {
      if (val) {
        this.collectpage.pageNum = val;
      } else {
        this.collectpage.pageNum = 1;
      }
      const collectRequest = {
        ...this.SeachParams,
        sku:this.sku,
        healthCodeAnalyse:this.healthCodeAnalyse,
        merchantId:this.merchantId,
        orderNo: this.formInline.orderNo,
        phoneName: this.copyphoneName,
        pageNum: this.collectpage.pageNum,
        pageSize: this.collectpage.pageSize,
      };
      _api.CollectionorderList(collectRequest).then(res => {
        if (res.code === 1) {
          this.collectDataList = res.data.records;
          this.collectpage.pageNum = res.data.current;
          this.collectpage.total = res.data.total;
        }
      });
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const baseRequest = {
        endTime: this.SeachParams.endTime,
        startTime: this.SeachParams.startTime,
        dateType: this.SeachParams.dateType,
        phoneName: this.SeachParams.phoneName,
        brandName: this.SeachParams.brandName,
        thanEndTime: this.SeachParams.thanEndTime,
        thanStartTime: this.SeachParams.thanStartTime,
        typeId: this.SeachParams.typeId,
        companyId: this.SeachParams.companyId,
        merchantId:this.merchantId,
        pageNum: this.page.pageNum,
        pageSize: this.SeachParams.pageSize
      };
      _api.phoneNameStatistics(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data;
        }
      });
    },
    gradeBtn(row) {
      this.copyphoneName = row.phoneName
      this.copypbrandName = row.brandName
      this.failOrderdata = row
      let gradePrams = {
        merchantId:this.merchantId,
        "brandName": row.brandName,
        "dateType": this.SeachParams.dateType,
        "endTime": this.SeachParams.endTime,
        "phoneName": row.phoneName,
        "sku": row.skuName,
        "startTime": this.SeachParams.startTime,
        "typeId": this.SeachParams.typeId
      }
      console.log(gradePrams);
      _api.biaoqiananalyse(gradePrams).then(res => {
        if (res.code === 1) {
          this.grademodelList = res.data.list;
          this.bindDloding = true
        }
      });
    },
    // 表格按钮操作
    handleBtn(row, type) {
      this.copyphoneName = row.phoneName
      this.copypbrandName = row.brandName
      let talPrams = {
        ...this.SeachParams,
        merchantId:this.merchantId,
        phoneName: this.copyphoneName,
        brandName: this.copypbrandName,
      }
      _api.skuStatistics(talPrams).then(res => {
        if (res.code === 1) {
          this.opymodelList = res.data;
          this.markShow = true
        }
      });
    },
    // //弹窗取消
    handleClose() {
      this.dialogVisible = false;
    },
    handleDialogClosed() {
      this.dialogVisible = false;
      this.handleCurrentChange(this.pageNum);
    },
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.mark {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.mark-color {
  background-color: #838383;
  border-color: #838383;
}

.el-button--info.is-disabled,
.el-button--info.is-disabled:hover {
  background-color: #838383 !important;
  border-color: #838383 !important;
}

.GlobalTable {
  background-color: #fff;
}

.img-el-heard {
  display: flex;
  justify-content: center;

  img {
    margin: 0px 0 -3px 3px;
  }
}

.equationBlue {
  color: #20A0FF;
}

.equationGreen {
  color: #49A915;
}

.equationRed {
  color: #FF4949;
}</style>
