<template>
  <div class="OrderList">
    <div class="dis al_c ju_s" style="margin-bottom: 20px">
      <div class="taber_scoll dis al_c">
        <div :class="topIndex==item.id?'selectStyle':'ts_center'" @click="selectOnheight(item.id)" v-for="item in scollData" :key="item.id">
          {{ item.name }}
        </div>
      </div>
      <div style="color:#0981ff;font-size:20px">{{$route.query.name}}</div>
    </div>
    <GlobalChunk v-if="topIndex==1" icon="search" title="筛选查询">
      <div slot="filter">
        <OrderFrom :classList="classList" :tabIndex="tabIndex" ref="childForm" />
      </div>
      <div class="caseOrder">
        <OrderTable @getUnPayUserOrders="getUnPayUserOrders" @tabChange="tabChange" />
      </div>
    </GlobalChunk>
    <GlobalChunk v-else icon="search" title="筛选查询">
      <div slot="filter">
        <From :classList="classList" :tabIndex="tabIndex" ref="childForm" />
      </div>
      <div class="caseOrder">
        <Table @getUnPayUserOrders="getUnPayUserOrders" />
      </div>
    </GlobalChunk>
  </div>
</template>

<script>
import OrderFrom from "./failOrderFrom"
import OrderTable from "./failOrderTable"
import From from "./From"
import Table from "./Table"
export default {
  name: "OrderList",
  components: { OrderFrom, OrderTable,From ,Table},
  data() {
    return {
      classList: null,
      topIndex: 1,//
      tabIndex: 0,//
      scollData:[{
        name:"非最高报价订单",
        id:1
      },{
        name:"非最高报价订单分析",
        id:2
      }]
    };
  },
  created() {
  },
  methods: {
    selectOnheight(id){
       this.topIndex=id
    },
    getUnPayUserOrders(e) {
      this.UnPayUserOrders = e
    },
     // tab改变了
     tabChange(e){
      this.tabIndex=e
      console.log(e,'eeeee');
    },
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.OrderList {
  .case {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .case-title {
      display: flex;

      .case-title_icon {
        width: 20px;
        height: 20px;
      }

      .case-title_max {
        font-weight: bold;
        color: #333333;
        font-size: 18px;
        width: 80px;
        height: 17px;
        line-height: 17px;
        font-family: FZLanTingHei-B-GBK;
      }
    }
  }

  .caseOrder {
    margin: 0 5px;
    background: #f5f6fa;
    border-radius: 10px;
  }
}

.taber_scoll {
  // margin-bottom: 20px;
  .ts_center {
    cursor: pointer;
    margin-right: 20px;
    text-align: center;
    width: 150px;
    height: 36px;
    border: 1px solid #0981FF;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #0981FF;
    line-height: 36px;
  }
  .selectStyle{
    cursor: pointer;
    margin-right: 20px;
    text-align: center;
    width: 150px;
    height: 36px;
    border: 1px solid #0981FF;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #FFFFFF;
    background: #0981FF;
    line-height: 36px;
  }
}
.dis{
  display: flex;
}
.al_c{
  align-items: center;
}
.ju_s{
  justify-content: space-between;
}
.ju_c{
  justify-content: center;
}
</style>
